@import "src/themes/mojito/styles/index.scss";
.main-logo {
  & .mobile {
    display: none;
  }

  & .tablet {
    display: none;
  }

  &--responsive {
    & .mobile {
      display: block;
    }

    & .tablet {
      display: none;
    }

    & .desktop {
      display: none;
    }

    @include xs {
      .main-logo .mobile {
        display: none;
      }

      .main-logo .tablet {
        display: block;
      }
    }

    @include lg {
      .main-logo .tablet {
        display: none;
      }

      .main-logo .desktop {
        display: block;
      }
    }
  }
}
