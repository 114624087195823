@import "src/themes/mojito/styles/index.scss";
.google-button {
  width: 100%;
  height: 2.5rem;
  border: 1px solid getvar($colors, "neutral", "300");
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: space(1.5);
  transition: box-shadow 0.1s linear;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
    background-color: getvar($colors, "neutral", "100");
  }

  &:hover {
    box-shadow: 0 5px 4px 0 rgba(89, 89, 89, 0.119);
  }

  &__icon {
    margin-right: 1rem;

    div {
      display: flex;
      align-items: center;
    }
  }

  &__label {
    margin-bottom: 0;
    color: getvar($colors, "neutral", "500");
  }
}

.google-auth-toast {
  max-width: 23rem;
  display: flex;
  background: #fe4256;
  padding: 1rem;
  border-radius: 8px;

  &__icon {
    color: white;
    font-size: 6rem;
    font-weight: 700;
    margin-right: 1.3rem;
  }

  &__message {
    color: white;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0 !important;
  }

  &__button {
    margin-left: 1.1rem;

    span {
      cursor: pointer;
      color: white;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}
