@import "src/themes/mojito/styles/index.scss";
.auth__container {
  display: flex;
  height: 100vh;

  @include lg {
    background-color: white;
  }

  &--form {
    width: 100%;
    margin: 0 auto;
    background-color: getvar($colors, "primary", "50");
    height: 100%;

    @include lg {
      width: 42%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      background-color: white;
      flex-direction: column;
    }

    .auth__form {
      display: flex;
      flex: 2;
      box-sizing: border-box;
      width: 100%;
    }

    .divider__or-separator::after {
      background-color: white !important;
    }
  }
  &--carousel {
    display: none;

    @include lg {
      width: 58%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: getvar($colors, "primary", "500");
    }
  }
  &__image-container {
    display: none;

    @include lg {
      width: 58%;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: getvar($colors, "primary", "500");
    }
    img {
      height: 100%;
    }
  }
}
