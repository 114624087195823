@import "src/themes/mojito/styles/index.scss";
.Auth {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* 
  max-width: 530px !important;
  @include sm {
    max-width: 100% !important;
  }
 */
  &__switcher {
    display: flex;
    margin-top: space(1);
    align-self: center;

    #signUp,
    #login {
      .button__text {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }

  button {
    margin-left: space(0.25);
  }
}

.Register,
.recover-password,
.Login {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 20px;
  min-width: 275px;
  max-width: 335px;
  box-shadow: 2px 0px 6px 0px #a6a6a640;
  padding: 30px 24px 24px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: white;
  @include sm {
    min-width: 672px;
    max-width: 672px;
  }

  @include lg {
    min-width: 469px;
    max-width: 469px;
    box-shadow: 0px 0px 0px 0px;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 0;
  }

  form {
    width: 100%;
    max-width: none;
    .Input {
      margin-bottom: space(1);
    }
    .button.button--full {
      margin: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.Login,
.recover-password,
.Register {
  &__title {
    align-self: flex-start;
    font-weight: 700;
    font-family: getvar($typography, "font-family-titles");
  }

  form button {
    margin-top: space(1.5) !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    @include lg {
      max-width: 325px !important;
    }
  }
}

.recover-password {
  &__subtitle {
    text-align: left;
    align-self: flex-start;

    .u-br-desktop {
      display: inline;
      @include lg {
        display: block;
      }
    }
  }

  .back-button {
    text-align: left;
    margin-bottom: space(1.5);
    display: flex;
    align-items: center;
    color: getvar($colors, "neutral", "500");
  }

  .resend-email {
    margin-top: space(1);
    display: flex;
    align-self: center;
  }

  .reset_password_success_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include lg {
      width: 460px;
    }

    .reset_password_success_icon {
      margin-bottom: space(1);
    }
    .recover-password__title,
    .recover-password__subtitle {
      text-align: center !important;
      align-self: center !important;
    }

    .success-button {
      width: 100%;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      justify-content: center;
    }
  }
}

.ReactModal__Content .recover-password {
  &__subtitle br {
    display: none;
  }
}

.Login {
  &__title {
    align-self: flex-start;
  }

  &__forgot-password {
    display: inline-block !important;
    margin-right: 10px;
  }

  .divider__or-separator::after {
    background-color: getvar($colors, "primary", "50");
  }
}

.Register,
.Login {
  .button {
    margin-top: 0 !important;
    margin-bottom: space(2);

    &--forgot-password {
      display: inline-block !important;
      padding-left: space(0.25);
      margin-top: 0 !important;
      @extend .u-spacing-5;
    }

    &--recover-password__secondary {
      margin-top: space() !important;
    }
    &--switch-form-button {
      margin-bottom: space(1);
    }
  }
  .button--forgot-password {
    //display: inline-block !important;
    display: contents !important;
    padding-left: space(0.25) !important;
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
    .button__text {
      font-size: 16px !important;

      font-weight: 400 !important;
      line-height: 24px !important;
      margin-bottom: 0.5rem !important;
    }
  }
}

.Checkbox--register {
  @extend .u-spacing-4;
}

.Register {
  .register-disclaimer {
    text-align: center;
  }
}

.qr-register {
  margin-top: 1.5rem;

  @include lg {
    margin-top: 0;
  }

  .banner-text-container {
    @include lg {
      margin-left: space(2.375);
    }

    p {
      margin-bottom: 0;

      @include sm {
        font-size: space(1);
        line-height: space(1.125);
      }

      @include lg {
        font-size: space(1.125);
        line-height: space(1.5);
      }
    }
    h3 {
      @include sm {
        font-size: space(1.125);
        line-height: space(1.5);
      }

      @include lg {
        font-size: space(1.5);
        line-height: space(2);
      }
    }
  }
  .qr-ready {
    @include sm {
      margin-right: space(1);
    }
    @include lg {
      margin-right: 0;
    }
  }
}
